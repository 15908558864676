import { useState, useEffect } from "react";
import styles from "./Navbar.module.scss";

import clsx from "clsx";
import { Divide as Hamburger } from "hamburger-react";
import { useNavigate, useLocation } from "react-router-dom";
import { ClickAwayListener } from "@mui/base";
import { FiClock } from "react-icons/fi";
import { format } from "date-fns";

import { isLoggedIn, getRole } from "utils/helper";
import { NavLinks } from "utils/consts";
import logo from "assets/images/logo.png";
import useScrollPosition from "hooks/useScrollPosition";

import { useAppContext } from "contexts/AppContext";
import { useUserContext } from "contexts/UserContext";

import StudentRegistrationModal from "components/StudentRegistration/StudentRegistrationModal";
import GoogleLoginBtn from "components-shared/GoogleLoginBtn/GoogleLogin";
import ProfileContainer from "components-shared/ProfileContainer/ProfileContainer";

import { Roles } from "utils/consts";

interface PropTypes {
  onSuccessGoogleLogin: any;
  state: any;
  toggleRegistrationModal: any;
}

const _NavLinks = [
  // {
  //   name: "Home",
  //   link: "https://ccc.training",
  //   isExternal: true,
  // },
  {
    name: "Challenges",
    link: "/contests",
  },
];

const OpaqueNavRoutes = ["/admin", "/login"];

export default function NavbarDark({ onSuccessGoogleLogin, state }: PropTypes) {
  const [activeRoute, setActiveRoute] = useState("");
  const [isTransparent, setIsTransparent] = useState(false);
  const [isMobileDropdownOpen, setIsMobileDropdownOpen] = useState(false);
  const [isRouteMatchOpaqueNav, setIsRouteMatchOpaqueNav] = useState(false);
  const [accessDate, setAccessDate] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();
  const { state: appState, toggleProfileUpdateModal } = useAppContext();
  const { state: userState } = useUserContext();

  function getAccessDate() {
    if (userState.profile.accessExpiryTime) {
      setAccessDate(format(new Date(userState.profile.accessExpiryTime), "dd MMM yyyy"));
    }
  }

  function handleClickAwayMobile(e) {
    if (e.type === "touchend") return;
    setIsMobileDropdownOpen(false);
  }

  function openHome() {
    window.scrollTo(0, 0);
    navigate("/");
  }

  function handleLogout() {
    localStorage.clear();
    window.location.href = "/";
  }

  function handleClickLink(link) {
    if (link.isExternal) {
      window.location = link.link;
      return;
    }

    if (link.name.toLowerCase() === "logout") return handleLogout();
    navigate(link.link);
  }

  function onCloseUserModal() {
    if (userState.isNew) return null;
    else toggleProfileUpdateModal();
  }

  function adjustNavTransparencyOnScroll({ currPos }) {
    if (isRouteMatchOpaqueNav) return;

    if (currPos.y < -90) setIsTransparent(false);
    else setIsTransparent(true);
  }

  function handleClickNav(navItem) {
    const { name, link } = navItem;
    setIsMobileDropdownOpen(false);

    if (name.toLowerCase() === "logout") return handleLogout();
    navigate(link);
  }

  useScrollPosition(adjustNavTransparencyOnScroll, [isTransparent, location, activeRoute]);

  useEffect(() => {
    getAccessDate();
  }, [userState.profile.accessExpiryTime]);

  useEffect(() => {
    let route = location.pathname.split("/")[1];
    if ([Roles.ContentAdmin, Roles.SuppL0, Roles.SuperAdmin].includes(getRole())) {
      setIsRouteMatchOpaqueNav(true);
    } else if (OpaqueNavRoutes.includes(window.location.pathname)) {
      setIsRouteMatchOpaqueNav(true);
    } else {
      setIsRouteMatchOpaqueNav(false);
    }

    setActiveRoute(`/${route}`);
  }, [location]);

  const currNavLinks = NavLinks[localStorage.getItem("role")];

  return (
    <div
      className={clsx(
        "withPadding",
        styles.wrapper,
        styles.dark,
        isTransparent && styles.transparent,
      )}
    >
      <div className={styles.container}>
        <div className={styles.logo} onClick={openHome}>
          <img data-textid="logo" src={logo} alt="logo" data-testid="logo-image" />
          <div>
            <span data-testid="Logo-heading1">Campus Corporate Connect</span>
            <span data-testid="Logo-heading2">The most trusted training partner in India</span>
          </div>
        </div>

        <div className={clsx(styles.desktopNavigation, styles.navigationWrapper)}>
          {_NavLinks.map((nav) => (
            <div
              key={nav.name}
              className={clsx(styles.nav)}
              onClick={handleClickLink.bind(this, nav)}
            >
              <p>{nav.name}</p>
              <span
                className={`${activeRoute === nav.link ? styles.activeRoute : null}`}
                style={{ backgroundColor: "#ffffff" }}
              ></span>
            </div>
          ))}

          <div className={clsx(styles.nav, styles.profileContainer)}>
            {isLoggedIn() ? (
              <div>
                <ProfileContainer color="light" accessDate={accessDate} />
              </div>
            ) : (
              <GoogleLoginBtn
                onSuccess={(payload) => {
                  onSuccessGoogleLogin(payload);
                }}
                onError={(err) => {
                  console.log(err);
                }}
              />
            )}
          </div>
        </div>

        {/* -------------------------------------- MOBILE NAVBAR --------------------------------------------------------*/}
        <ClickAwayListener onClickAway={handleClickAwayMobile}>
          <div className={clsx(styles.mobileNavigation, styles.navigationWrapper)}>
            <div className={styles.hamburger}>
              <Hamburger
                toggled={isMobileDropdownOpen}
                toggle={setIsMobileDropdownOpen}
                color={"#ffffff"}
              />
            </div>
            <div
              className={clsx(styles.dropdown, isMobileDropdownOpen ? styles.open : styles.closed)}
            >
              {!isLoggedIn() ? (
                <div className={styles.nav}>
                  <GoogleLoginBtn
                    onSuccess={(payload) => {
                      onSuccessGoogleLogin(payload);
                    }}
                    onError={(err) => {
                      console.log(err);
                    }}
                  />
                </div>
              ) : (
                <>
                  {currNavLinks?.map((item) => (
                    <div
                      key={item.name}
                      className={styles.nav}
                      onClick={handleClickNav.bind(this, item)}
                    >
                      {item.icon}
                      <p style={{ marginLeft: "10px" }}>{item.name}</p>
                    </div>
                  ))}
                  {localStorage.getItem("role") === "institutionAdmin" && (
                    <div className={styles.nav}>
                      <FiClock />
                      <p style={{ marginLeft: "10px", textAlign: "center" }}>
                        Subscription ends at <br />
                        {accessDate}
                      </p>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </ClickAwayListener>
      </div>

      <StudentRegistrationModal
        isOpen={appState.isOpenProfileUpdateModal}
        onClose={onCloseUserModal}
      />
    </div>
  );
}
