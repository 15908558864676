import { deleteRequest, getRequest, patchRequest, postRequest } from "./setup";

export function fetchAllPlatforms() {
  return getRequest(`/platforms/all`);
}

export function fetchAllInstitutions() {
  return getRequest(`/institutions/all`);
}

export function fetchAllSpecialisations() {
  return getRequest(`/institutions/specialisations/all`);
}

export function fetchAllBranches() {
  return getRequest(`/institutions/branches/all`);
}

export function fetchAllTopics() {
  return getRequest(`/topics/all`);
}
export function fetchAllTags() {
  return getRequest(`/tags/all`);
}

export function fetchAllCodingPlatforms() {
  return getRequest(`/coding-platforms`);
}

export function fetchAllCodeForcesTags() {
  return getRequest(`/practice/codeforces-tags/all`);
}

export function fetchTrainers() {
  return getRequest(`/attendance/trainer/all`);
}

export function createTrainer(body) {
  return postRequest(`/attendance/trainer`, body);
}

export function createPublication(body) {
  return postRequest(`/attendance/attendance-publication`, body);
}

export function fetchAllPublications(query) {
  return getRequest(`/attendance/attendance-publication/all`, query);
}

export function downloadAttendence(query) {
  return getRequest(`/attendance/report`, query, "blob");
}

export function deleteAttendence(publicationId) {
  return deleteRequest(`/attendance/attendance-publication/${publicationId}`);
}

export function editTrainerDetails(body) {
  return patchRequest(`/attendance/trainer`, body);
}

export function editPublicationDetails(body) {
  return patchRequest(`/attendance/attendance-publication`, body);
}

export function submitPublication(body) {
  return postRequest(`/attendance/submit-form`, body);
}
