export const routes = {
  Dashboard: "/dashboard",

  Login: "/login",
  CodingProfileStudent: "/coding-profile",
  Error: "/error",
  Contests: "/contests",

  LicensesList: "/licenses",
  AllocatedLicenses: "/licenses/allocated",

  StudProfile: "/profile/student",
  StudCodingProfiles: "/coding-profiles",
  StudProblemSets: "/problem-sets/student",
  StudAccount: "/account",
  Students: "/students",
  Attendence: "/attendance",

  Goals: "/goals",
  Groups: "/groups",

  Assessments: "/assessments",

  ProbSets: "/problem-sets",
  PubProbSets: "/problem-sets/published",
  InstPubProbSets: "/problem-sets/published/institutions",

  TagsTopics: "/tags-topics",
  Problems: "/problems",
  StudAssessments: "/assessments/student",
  AssessmentQuiz: "/start-quiz",
  PubAssements: "/assessments/published",
  ViewPubAssements: "/assessments/published",
  StudViewPubAssements: "assessments/publQuizished/view/student",
  SuppInstAdmins: "/supportUpdatePassword",
};
